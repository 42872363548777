import React, { useEffect } from "react";

const AboutBanner = (props) => {
  return (
    <>
      <div className="AboutBanner">
        <div className="container">
          <h4 data-aos="fade-up">About Us</h4>
          <h1 data-aos="fade-up">
            We are here to serve your cybersecurity needs
          </h1>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;
