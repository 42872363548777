import React from "react";
import { Link } from "react-router-dom";
import { formatDate, imageUrlBasePath } from "../utils/helpers";

const SingleCard = ({
  cardName,
  cardUrl,
  // isSealed,
  type,
  releaseDate,
  price = "",
  Cheapest = "",
  showBackBtn = false,
  onClick,
}) => {
  return (
    <div className="item-box" onClick={onClick}>
      {showBackBtn ? (
        <>
          <Link className="back-btn" to="/allproducts">
            <img src="/images/arrow.svg" alt="icon" />
            <span>Go Back to All Products</span>
          </Link>
        </>
      ) : null}
      <div
        className="itemimg"
        style={{ backgroundImage: `url(${imageUrlBasePath(cardUrl)})` }}
      ></div>
      <div className="item-detail">
        <h4>{cardName}</h4>
        {/* <h5>{isSealed ? "Sealed" : "Not Sealed"}</h5> */}
        <h5>{type}</h5>
        <h6>Release date: {formatDate(releaseDate)}</h6>

        <div className="detailprice">
          <div className="relative">
            <p>{Cheapest}</p>
            <p className="price">{price}</p>
          </div>
          <p className="arrow">
            <img src="/images/arrow.svg" alt="icon" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
