import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { addRequest } from "../../services/card-services";
import { toast } from "react-toastify";

const Request = (props) => {
  const [product, setProduct] = useState("");

  const handleChange = (e) => {
    setProduct(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addRequest({ product });
      toast.success(response.message);
      console.log(response);
      setProduct("");
      props.onHide();
    } catch (error) {
      toast.error(error.message);
      console.error("Error adding request:", error);
      // Handle error as needed
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="SubscribeModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Request Us a Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="forminputbar">
            <input
              placeholder="Product Name"
              type="text"
              className="form-control"
              name="product"
              value={product}
              onChange={handleChange}
            />
          </div>
          <div className="btnsubmit">
            <button type="submit" className="btn btnblack">
              Request Now
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Request;
