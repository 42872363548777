import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { addSubscriber } from "../../services/card-services";
import { toast } from "react-toastify";

const SubscribeModal = (props) => {
  const [emailData, setEmailData] = useState("");

  const handleChange = (e) => {
    setEmailData(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (!validateEmail(emailData)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      const response = await addSubscriber({ email: emailData });
      toast.success(response.message || "You have subscribed successfully!");
      setEmailData("");
      props.onHide();
    } catch (error) {
      toast.error(error?.message ?? "Subscription failed. Please try again.");
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="SubscribeModal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Subscribe to newsletter today!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="forminputbar">
          <input
            placeholder="Enter your Email"
            type="email"
            id="exampleForm.ControlInput1"
            className="form-control"
            name="email"
            value={emailData}
            onChange={handleChange}
            required
          />
        </div>
        <br />
        <ul className="listing">
          <li>
            <img src="/images/tick.svg" alt="icon" />
            Access to Daily Deals
          </li>
          <li>
            <img src="/images/tick.svg" alt="icon" />
            Access to Opportunity Finder
          </li>
          <li>
            <img src="/images/tick.svg" alt="icon" />
            Access to Singles
          </li>
        </ul>
        <br />
        <div className="btnsubmit">
          <button className="btn btnblack" onClick={handleSubscribe}>
            Subscribe
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscribeModal;
