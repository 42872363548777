import React, { useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
const ProductTitle = ({ title }) => {
  return (
    <>
      <div className="ProductTitle">
        <div className="container">
          {/* <Breadcrumb className="breadcrumbs-bar">
            <Link className="breadcrumb-item" to="/">
              Home
            </Link>
            <Link className="breadcrumb-item " to="/">
              Sets
            </Link>
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb> */}
          {/* <h1 data-aos="fade-up">{title}</h1>
          <p data-aos="fade-up">
            Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget
            blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et
            aliquam odio vitae.
          </p> */}
          {/* <div data-aos="fade-up" className="linered"></div> */}
        </div>
      </div>
    </>
  );
};

export default ProductTitle;
