import React from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "../Headers/MainHeader";
import Footer from "../Footer";

const HomeLayout = () => {
  return (
    <>
      <div className="max-width-window">
        <MainHeader />
        <div className="home-wrapper">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomeLayout;
