import React, { useEffect } from "react";

const VerityCards = (props) => {
  return (
    <>
      <div className="VerityCards">
        <div className="container">
          <div className="cardblock" data-aos="fade-up">
            <div className="badgeimg">
              <img src="/images/badge.png" alt="logo" />
            </div>
            <h3>
              We have wide verity of pokemon cards to compare between various
              websites
            </h3>
            <p>
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat
              sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet
              consectetur. Vel curabitur porta dui eget blandit amet tempor.
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Lorem ipsum dolor sit amet consectetur.
              Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac
              ipsum eleifend feugiat sit. Et aliquam odio vitae. Lorem ipsum
              dolor sit amet consectetur. Vel curabitur porta dui eget blandit
              amet tempor. Lorem ipsum dolor sit amet consectetur. Vel curabitur
              porta dui eget blandit amet tempor.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerityCards;
