import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import SingleCard from "../SingleCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { cardInfo } from "../../services/card-services";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <i
        className="bi bi-arrow-left"
        style={{ fontSize: "24px", color: "#000" }}
      ></i>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <i
        className="bi bi-arrow-right"
        style={{ fontSize: "24px", color: "#000" }}
      ></i>
    </div>
  );
};

const RecentlyViewed = () => {
  const [recentCards, setRecentCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecentCards = async () => {
      const viewedCards =
        JSON.parse(localStorage.getItem("recentlyViewedCards")) || [];
      const validCards = [];

      for (const id of viewedCards) {
        try {
          const response = await cardInfo(id);
          validCards.push(response.data);
        } catch (error) {
          console.log(error);
        }
      }

      setRecentCards(validCards);
      setLoading(false); // Set loading to false when the data is fetched
    };

    fetchRecentCards();
  }, []);

  const settings = {
    dots: true,
    className: "center",
    slidesToShow: 5,
    autoplay: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    focusOnSelect: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 574,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="RecentlyViewed" data-aos="fade-up">
        <h2 className="recently-card">Recently Viewed Products</h2>
        <div className="product_slider">
          {
            <Slider {...settings}>
              {loading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} className="skeleton-wrapper">
                      <Skeleton height={150} width={200} />
                      <Skeleton width={`80%`} />
                      <Skeleton width={`60%`} />
                    </div>
                  ))
                : recentCards.map((card) => (
                    <Link to={`/productdetail/${card?.slug}`} key={card._id}>
                      <SingleCard
                        cardName={card.cardName}
                        cardUrl={card.cardUrl}
                        releaseDate={card.releaseDate}
                        type={card?.type}
                        price={card?.price ? `£${card?.price.toFixed(2)}` : ""}
                        Cheapest={card?.price ? "Starting from" : ""}
                      />
                    </Link>
                  ))}
            </Slider>
          }
        </div>
      </div>
    </>
  );
};

export default RecentlyViewed;
