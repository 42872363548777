import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/Css/style.css";
import "../src/Css/responsive.css";
import PrimeLayout from "./Components/Layout/PrimeLayout";
import ClientLayout from "./Components/Layout/ClientLayout";
import Home from "./Pages/Home";
import AllProduct from "./Pages/Product/AllProduct";
import AboutUs from "./Pages/AboutUs";
import Search from "./Pages/Search";
import ProductDetail from "./Pages/Product/ProductDetail";
import Singles from "./Pages/Product/Singles";
import Gradedslabs from "./Pages/Product/Gradedslabs";
import Sellertools from "./Pages/Product/Sellertools";
import Nosearch from "./Pages/Search/Nosearch";
import ReactGA from "react-ga";

const TRACKING_ID = "G-E4PV0TEP2G";
ReactGA.initialize(TRACKING_ID);
const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-page", location.pathname);
    console.log(`sending tracking for ${location.pathname}`);
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <Routes>
      <Route element={<PrimeLayout />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/allproducts" element={<AllProduct />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/singles" element={<Singles />} />
        <Route exact path="/gradedslabs" element={<Gradedslabs />} />
        <Route exact path="/sellertools" element={<Sellertools />} />
        <Route exact path="/nosearch" element={<Nosearch />} />
        <Route exact path="/productdetail/:slug" element={<ProductDetail />} />
      </Route>
      <Route element={<ClientLayout />}>
        {/* <Route exact path='/home' element={<Home />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRouter;
