import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PriceCardData } from "../../services/card-services";
import moment from "moment";

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ cardId }) => {
  const [priceCardData, setPriceCardData] = useState("");
  const [lowestCurrentPrice, setLowestCurrentPrice] = useState(null);
  const [highestCurrentPrice, setHighestCurrentPrice] = useState(null);
  const [filterType, setFilterType] = useState("weekly");

  console.log({ priceCardData });

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  useEffect(() => {
    const fetchPriceCardData = async () => {
      try {
        const payload = {
          cardId: cardId,
          filterType: filterType,
        };
        const priceCardsResponse = await PriceCardData(payload);
        setPriceCardData(priceCardsResponse.data);
        setLowestCurrentPrice(
          priceCardsResponse?.data?.chartResults[0]?.amount || 0
        );
        setHighestCurrentPrice(
          priceCardsResponse?.data?.chartResults?.[
            priceCardsResponse?.data?.chartResults?.length - 1
          ]?.amount || 0
        );

        console.log("priceCardsResponse", priceCardsResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPriceCardData();
  }, [cardId, filterType]);

  const getChartData = () => {
    if (!priceCardData) return { labels: [], datasets: [] };

    const chartResults = priceCardData?.chartResults;

    let labels = chartResults?.map((data) => moment(data?.date).format('DD MMM'));
    if (filterType === "yearly") {
      labels = chartResults?.map((data) => moment(data?.date).format('YYYY'));
    } else if (filterType === "monthly") {
      labels = chartResults?.map((data) => moment(data?.date).format('MMM, YYYY'));
    } else if (filterType === "weekly") {
      labels = chartResults?.map((data) => moment(data?.date).format('DD MMM, YYYY'));
    }

    const dataValues = chartResults?.map((data) => data?.amount.toFixed(2));

    return {
      labels,
      datasets: [
        {
          label: "Price",
          data: dataValues,
          fill: false,
          borderColor: "#D01D24",
          tension: 0.6,
        },
      ],
    };
  };

  const data = getChartData();

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 15,
          font: {
            size: 14, // Set font size for y-axis labels
          },
          callback: function (value) {
            return "£" + value; // Add a dollar sign in front of the value
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 14, // Set font size for x-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Average Price History",
      },
    },
  };

  return (
    <>
      {priceCardData != "" && (
        <div className="linechart">
          <div className="linechart-head">
            <h2>
              <img src="/images/history.svg" alt="logo" />
              Average Price History
            </h2>
            <ul>
              {/* <li>Current Price:</li>
              <li className="text-green">{`£${lowestCurrentPrice} - £${highestCurrentPrice}`}</li>
              <li>
                <select onChange={handleFilterChange} value={filterType}>
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                  <option value="last7days">Weekly</option>
                </select>
              </li> */}
            </ul>
          </div>
          <Line data={data} options={options} />
        </div>
      )}
    </>
  );
};

export default LineChart;
