import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SubscribeModal from "../../Modals/SubscribeModal";
import { Link, useLocation } from "react-router-dom";

const MainHeader = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <div className="MainHeader">
      <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          <Link to="/">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
          <div className="headersetUI">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />
            <a href="https://www.instagram.com/tcgsearch/" target="_blank" className="btninsta">
              <i class="bi bi-instagram"></i>
            </a>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Link
                className={`nav-link ${
                  isActive("/allproducts") ? "active" : ""
                }`}
                to="/allproducts"
                onClick={handleLinkClick}
              >
                All Products
              </Link>
              {/* <Link
                className={`nav-link ${isActive("/singles") ? "active" : ""}`}
                to="/singles"
                onClick={handleLinkClick}
              >
                Singles
              </Link>
              <Link
                className={`nav-link ${
                  isActive("/gradedslabs") ? "active" : ""
                }`}
                to="/gradedslabs"
                onClick={handleLinkClick}
              >
                Graded Slabs
              </Link> */}
              {/* <Link
                className={`nav-link ${
                  isActive("/sellertools") ? "active" : ""
                }`}
                to="/sellertools"
                onClick={handleLinkClick}
              >
                Seller Tools
              </Link>
              <Link
                className={`nav-link ${isActive("/aboutus") ? "active" : ""}`}
                to="/aboutus"
                onClick={handleLinkClick}
              >
                About Us
              </Link> */}
            </Nav>
            <div className="socialmediabar">
              <button
                type="button"
                className="btn btnblack"
                onClick={() => setModalShow(true)}
              >
                Subscribe Now
              </button>
              <a href="https://www.instagram.com/tcgsearch/" target="_blank" className="btninsta">
                <i class="bi bi-instagram"></i>
              </a>
              <SubscribeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MainHeader;
