export const endpoint = {
  auth: {
    LOGIN: "/auth/login",
  },
  card: {
    SEARCH: "/card/search/list",
    ADD: "/card",
    UPDATE: "/card/update/",
    DELETE: "/card/delete/",
    INFO: "/card/",
    POPULAR: "/card/cards/popular",
    DETAILS: "/card/cardDetails",
    CARD_COMPARISON: "/card/cards-comparison",
    CARD_PRICE: "/card/get-cards-price",
    SUBSCRIBER: "/card/subscribe",
    REQUEST: "/card/addRequest",
    FILTER: "/card/search-languages",
  },
};
