import React, { useEffect } from "react";

const howworkData = [
  {
    title: "Find a Product",
    image: "/images/l1.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae.",
  },
  {
    title: "Check average Price stats",
    image: "/images/l2.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae.",
  },
  {
    title: "Compare Price With Different Websites",
    image: "/images/l3.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae.",
  },
  {
    title: "Buy Your Product",
    image: "/images/l4.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae.",
  },
];

const HowWork = (props) => {
  return (
    <>
      <div className="HowWork">
        <div className="workrow">
          <div className="rowleft">
            <h2 data-aos="fade-up">how it Works</h2>
            <p data-aos="fade-up">
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat
              sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet
              consectetur. Vel curabitur porta dui eget blandit amet tempor.
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor.
            </p>
            <ul className="animated-imgs">
              <li data-aos="fade-right">
                <img src="/images/v1.png" alt="vector img" />
              </li>
              <li data-aos="fade-up">
                <img src="/images/v2.png" alt="vector img" />
              </li>
              <li data-aos="fade-down">
                <img src="/images/v3.png" alt="vector img" />
              </li>
            </ul>
          </div>
          <div className="rowryt">
            {howworkData.map((item, index) => (
              <div key={index} data-aos="fade-up" className="rytlist">
                <img src={item.image} alt="logo" />
                <div className="logocontent">
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWork;
