import React, { useEffect } from "react";

const processData = [
  {
    title: "Our Vision",
    image: "/images/checkmark.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta djndh blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    title: "Our Mission",
    image: "/images/checkmark.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta djndh blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    title: "What We Do",
    image: "/images/checkmark.svg",
    content:
      "Lorem ipsum dolor sit amet consectetur. Vel curabitur porta djndh blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet consectetur.",
  },
];

const Process = (props) => {
  return (
    <>
      <div className="Process">
        <div className="container">
          <div className="Process-title">
            <h3 data-aos="fade-up">All about us and our process</h3>
            <p data-aos="fade-up">
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat
              sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet
              consectetur. Vel curabitur porta dui eget blandit amet tempor.
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat
              sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet
              consectetur.
            </p>
          </div>
          <div className="row">
            {processData.map((item, index) => (
              <div key={index} className="col-md-4" data-aos="fade-up">
                <div className="process-box">
                  <img src={item.image} alt="icon" />
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
