import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import AnimatedNumber from "./AnimatedNumber";
import { cardSearch } from "../../services/card-services";
import Autosuggest from "react-autosuggest";

const Banner = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (event, { newValue }) => {
    setSearchTerm(newValue);
  };

  const handleSearch = () => {
    navigate(`/search?card=${searchTerm}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const fetchSuggestions = async ({ value }) => {
    try {
      const response = await cardSearch({ searchTerm: value });
      setSuggestions(
        response.data.docs.map((card) => ({
          slug: card.slug,
          cardName: card.cardName,
        }))
      ); // Mapping to an array of objects containing both slug and cardName
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions({ value });
  };

  const onSuggestionsClearRequested = () => {
    clearSuggestions();
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    console.log("suggestionsss:::", suggestion.slug);
    navigate(`/productdetail/${suggestion.slug}`); // Assuming the card object has a 'slug' property
    clearSuggestions();
  };

  const inputProps = {
    placeholder: "Search sealed product e.g. pokemon 151 or Silver Tempest",
    value: searchTerm,
    onChange: handleInputChange,
    onKeyPress: handleKeyPress,
    className: "form-control",
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">{suggestion.cardName}</div>
  );

  return (
    <div className="container">
      <div className="Bannerbar">
        <div className="wrapper">
          {[...Array(15)].map((_, index) => (
            <div key={index}>
              <span className="dot"></span>
            </div>
          ))}
        </div>

        <div className="bannerrow">
          <h1 data-aos="fade-up">
            TCG Sealed & Singles Price Comparison Site
          </h1>
          <h6 data-aos="fade-up">Built by the community, For the community</h6>
          <div data-aos="fade-up" className="bannersearch">
            <Form>
              <Form.Group
                className="forminputbar"
                controlId="exampleForm.ControlInput1"
              >
                <img
                  className="searchicon"
                  src="/images/search.svg"
                  alt="search icon"
                />
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={(suggestion) => suggestion}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  theme={{
                    container: "autosuggest-container",
                    suggestionsContainer: "autosuggest-suggestions-container",
                    suggestionsList: "autosuggest-suggestions-list",
                    suggestion: "autosuggest-suggestion",
                    suggestionHighlighted:
                      "autosuggest-suggestion--highlighted",
                  }}
                />
                <button
                  type="button"
                  className="btn btnblack"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </Form.Group>
            </Form>
          </div>
          <AnimatedNumber />
        </div>
      </div>
    </div>
  );
};

export default Banner;
