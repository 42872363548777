import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import SingleCard from "../../Components/SingleCard";
import { cardSearch } from "../../services/card-services";
import { useQuery } from "../../utils/helpers";
import Nosearch from "./Nosearch";

const Search = (props) => {
  const query = useQuery();
  const initialSearchTerm = query.get("card") || "";
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState(""); // New state for searched term

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await cardSearch({ searchTerm });
      setSearchResults(response.data.docs);
      updateUrl(searchTerm);
      setSearchedTerm(searchTerm); // Update searched term
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const updateUrl = (term) => {
    if (window.history.pushState) {
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?card=" +
        encodeURIComponent(term);
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  useEffect(() => {
    if (initialSearchTerm) {
      handleSearch();
    }
  }, []);

  return (
    <div className="Search">
      <div className="ProductTitle">
        <div className="container">
          <Breadcrumb className="breadcrumbs-bar">
            <Link className="breadcrumb-item" to="/">
              Home
            </Link>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="container">
        <div className="searchform">
          <div data-aos="fade-up" className="bannersearch">
            <Form>
              <Form.Group
                className="forminputbar"
                controlId="exampleForm.ControlInput1"
              >
                <img
                  className="searhicon"
                  src="/images/search.svg"
                  alt="search icon"
                />
                <Form.Control
                  type="text"
                  placeholder="Search sealed product e.g. pokemon 151 or Silver Tempest"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  type="button"
                  className="btn btnblack"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </Form.Group>
            </Form>
          </div>
        </div>
        {searchResults.length === 0 ? (
          <Nosearch searchTerm={searchedTerm} />
        ) : (
          <div className="resultbar">
            <h2 className="h2 " data-aos="fade-up">
              You searched for “{searchedTerm}” {/* Use searchedTerm here */}
            </h2>
            <h5 className="h5" data-aos="fade-up">
              {searchResults.length} Result
              {searchResults.length !== 1 ? "s" : ""} Found
            </h5>
            <div className="products-search-list">
              <h4 className="h4" data-aos="fade-up">
                Results:
              </h4>
              <div className="product-appear">
                <div className="slick-slide allproduct-list">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    searchResults.map((card) => (
                      <Link
                        key={card._id}
                        to={`/productdetail/${card.slug}`}
                        data-aos="fade-up"
                      >
                        <SingleCard
                          cardName={card.cardName}
                          cardUrl={card.cardUrl}
                          releaseDate={card.releaseDate}
                          // isSealed={card.isSealed}
                          type={card?.type}
                          price={card?.price ? `£${card?.price.toFixed(2)}` : ""}
                          Cheapest={card?.price ? "Starting from" : ""}
                        />
                      </Link>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
