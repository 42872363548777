import React, { useEffect, useState } from "react";
import { cardComparison, getCard } from "../../services/card-services";
import { Link } from "react-router-dom";
import ProductDetail from "./ProductDetail";
import { constants } from "../../config/constants";
import { haveValue, imageUrlBasePath } from "../../utils/helpers";

const WebsiteComparison = ({ cardId, onMinPriceChange }) => {
  const [activeTab, setActiveTab] = useState("usa");
  const [comparisonData, setComparisonData] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchCardIdAndComparisonData = async () => {
      try {
        const comparisonResponse = await cardComparison(cardId);
        console.log("comparisonResponse", comparisonResponse);
        setComparisonData(comparisonResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCardIdAndComparisonData();
  }, [cardId]);

  const isVerified = (company) => {
    if (haveValue(company?.companyData?.isVerified)) {
      if (company?.companyData?.isVerified.toString() === "true") {
        return true;
      }
    }
    return false;
  };

  const getImage = (company) => {
    console.log(company);
    if (haveValue(company?.companyData?.image)) {
      return imageUrlBasePath(company?.companyData?.image);
    }
    return `https://ui-avatars.com/api/?name=${encodeURI(
      company?.company
    )}&background=C20000&color=fff`;
  };

  if (comparisonData?.docs.length > 0) {
    return (
      <div className="linechart websiteblocklist">
        <div className="linechart-head">
          <h2>
            <img src="/images/Websites.svg" alt="logo" />
            Websites comparison
          </h2>
        </div>
        <div className="websiteblocklist-inner">
          <div className="tab-content">
            {comparisonData &&
              comparisonData.docs.map((item, index) => (
                <a
                  href={item.cardLink}
                  target="_blank"
                  key={index}
                  className={`mb-3 content-box ${activeTab}clickshow`}
                >
                  <div className="contentbox-left">
                    <div className="logobar">
                      {isVerified(item) ? (
                        <>
                          <img
                            className="verified-icon"
                            src={`/images/verified.png`}
                          />
                        </>
                      ) : null}
                      <img src={getImage(item)} alt="logo" className="round" />
                    </div>
                    <div className="contentbox">
                      <span>
                        <h6>{item.company}</h6>
                      </span>
                      <p>{item.productTitle}</p>
                    </div>
                  </div>
                  <div className="arrowtext">
                    <span>
                      <h5>
                        £{item.price.toFixed(2)}{" "}
                        <img src="/images/carot.svg" alt="arrow" />
                      </h5>
                    </span>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  }
};

export default WebsiteComparison;
