import React, { useMemo, useState, useEffect } from "react";
import ProductTitle from "./ProductTitle";
import ProductSearchFilter from "../../Components/Products/ProductSearchFilter";
import useCard from "../../hooks/useCard";
import SingleCard from "../../Components/SingleCard";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useQuery } from "../../utils/helpers";
import { cardSearch } from "../../services/card-services";
import Autosuggest from "react-autosuggest";
import ReactPaginate from "react-paginate";
import "../../Css/style.css";

const AllProduct = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const initialSearchTerm = query.get("card") || "";
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 12,
    pageCount: 0,
  });
  const [filter, setFilter] = useState({
    language: [],
    era: [],
    type: [],
    set: [],
    sort: "latest",
  });

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await cardSearch({
        searchTerm,
        page: pagination.page,
        limit: pagination.pageSize,
        ...filter,
      });
      setSearchResults(response.data.docs);
      setPagination((prev) => ({
        ...prev,
        pageCount: response?.data?.totalPages || 0,
      }));
      updateUrl(searchTerm);
      setSearchedTerm(searchTerm);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      sort: e.target.value,
    }));
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleInputChange = (event, { newValue }) => {
    setSearchTerm(newValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const updateUrl = (term) => {
    if (window.history.pushState) {
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?card=" +
        encodeURIComponent(term);
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const handlePageClick = (e) => {
    let page = e.selected + 1;
    setPagination((prev) => ({
      ...prev,
      page: page,
    }));
  };

  useEffect(() => {
    handleSearch();
  }, [pagination.page, filter]);

  useEffect(() => {
    if (initialSearchTerm) {
      handleSearch();
    }
  }, []);

  const fetchSuggestions = async ({ value }) => {
    try {
      const response = await cardSearch({ searchTerm: value });
      setSuggestions(
        response.data.docs.map((card) => ({
          slug: card.slug,
          cardName: card.cardName,
        }))
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions({ value });
  };

  const onSuggestionsClearRequested = () => {
    clearSuggestions();
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    updateUrl(suggestion.slug);
    setSearchTerm(suggestion.cardName);
    handleSearch();
    navigate(`/productdetail/${suggestion.slug}`);
  };

  const inputProps = {
    placeholder: "Search sealed product e.g. pokemon 151 or Silver Tempest",
    value: searchTerm,
    onChange: handleInputChange,
    onKeyPress: handleKeyPress,
    className: "form-control autosuggest-input",
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">{suggestion.cardName}</div>
  );

  const options = useMemo(() => ({ ...filter }), [filter]);

  const { cardList, error } = useCard(options);
  const pageTitle = "All products";
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="AllProduct">
        <ProductTitle title={pageTitle} />
        <div className="searchform">
          <div data-aos="fade-up" className="bannersearch">
            <Form>
              <Form.Group
                className="forminputbar"
                controlId="exampleForm.ControlInput1"
              >
                <img
                  className="searhicon"
                  src="/images/search.svg"
                  alt="search icon"
                />
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={(suggestion) => suggestion.cardName}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  theme={{
                    container: "autosuggest-container",
                    suggestionsContainer: "autosuggest-suggestions-container",
                    suggestionsList: "autosuggest-suggestions-list",
                    suggestion: "autosuggest-suggestion",
                    suggestionHighlighted:
                      "autosuggest-suggestion--highlighted",
                  }}
                />
                <button
                  type="button"
                  className="btn btnblack"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="products-filter-block grid-setting">
          <div className={`filterrow ${isFilterOpen ? "open" : ""}`}>
            <div className="filter-searchbar opensearchfilter">
              <ProductSearchFilter
                closefun={toggleFilter}
                filter={filter}
                setFilter={setFilter}
              />
            </div>
            <div className="product-appear">
              <div className="product-top-bar">
                <h2 className="product-title">Products</h2>

                <Form.Select
                  value={filter?.sort}
                  className="top-sort-filter"
                  onChange={handleSortChange}
                >
                  <option value="latest">Latest Added</option>
                  <option value="oldest">Oldest Added</option>
                  <option value="release_date_desc">
                    Release Date (High to Low)
                  </option>
                  <option value="release_date_asc">
                    Release Date (Low to High)
                  </option>
                  <option value="price_desc">Price (High to Low)</option>
                  <option value="price_asc">Price (Low to High)</option>
                </Form.Select>
                <div className="btn-active-filter">
                  <button className="btn btnblack" onClick={toggleFilter}>
                    Filter By: <i className="bi bi-funnel-fill"></i>
                  </button>
                </div>
              </div>
              <div className="slick-slide allproduct-list">
                {loading ? (
                  <h1>Loading.....</h1>
                ) : (
                  searchResults.map((card) => (
                    <Link to={`/productdetail/${card?.slug}`} key={card._id}>
                      <SingleCard
                        cardName={card?.cardName}
                        cardUrl={card?.cardUrl}
                        releaseDate={card?.releaseDate}
                        type={card?.type}
                        price={card?.price ? `£${card?.price.toFixed(2)}` : ""}
                        Cheapest={card?.price ? "Starting from" : ""}
                      />
                    </Link>
                  ))
                )}
              </div>
              <div className="react-paginate-wrapper mt-5">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  initialPage={pagination.page - 1}
                  pageCount={pagination.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination-student"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProduct;
