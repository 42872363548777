import { Link } from "react-router-dom";
import React from "react";

const Hesitation = () => {
  return (
    <div className="Hesitation">
      <div className="dragon-img">
        <img src="/images/dragon.png" alt="Dragon" />
      </div>
      <div className="hesitation-content">
        <h3 data-aos="fade-up">
          Buy authenticated cards with zero <span>hesitation</span>
        </h3>
        <p data-aos="fade-up">
          Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui eget
          blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat sit. Et
          aliquam odio vitae. Lorem ipsum dolor sit amet consectetur. Vel
          curabitur porta dui eget blandit amet tempor. Lorem ipsum dolor sit
          amet consectetur. Vel curabitur porta dui eget blandit amet tempor.
        </p>
        <div data-aos="fade-up" className="btnred">
          <Link className="btn btnblack" to="/allproducts">
            Shop Cards
            <img src="/images/arrow.svg" alt="arrow icon" />
          </Link>
        </div>
      </div>
      <div className="rytstikcybar">
        <div className="shapebar">
          <img src="/images/ryt-round-shape.png" alt="Shape" />
        </div>
        <ul className="sticky-animation-imgs">
          <li>
            <img data-aos="fade-left" src="/images/collagpe.png" alt="1" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Hesitation;
