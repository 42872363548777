import { useEffect, useState } from "react";
import { getCardList } from "../services/card-services";

const useCard = (options) => {
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resData = await getCardList({ ...options });
        if (resData.status === 200) {
          const { data } = resData;
          setLoading(false);
          setCardList(data.docs);
        }
      } catch (error) {
        setLoading(false);
        console.log("🚀 ~ while fetch get List:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [options]);

  return {
    cardList,
    loading,
    error,
  };
};

export default useCard;
