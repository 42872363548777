import React, { useEffect } from "react";

const FavCard = (props) => {
  return (
    <>
      <div className="FavCard">
        <div className="container">
          <div className="carleft">
            <img
              data-aos="fade-up"
              className="pokemon-img"
              src="/images/pokemon.png"
              alt="icon"
            />
            <div data-aos="fade-up" className="cardbar">
              <h4>Choose your favorite pokemon card or accessory.</h4>
            </div>
          </div>
          <div className="cardryt">
            <p data-aos="fade-up">
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Amet ultrices ac ipsum eleifend feugiat
              sit. Et aliquam odio vitae. Lorem ipsum dolor sit amet
              consectetur. Vel curabitur porta dui eget blandit amet tempor.
              Lorem ipsum dolor sit amet consectetur. Vel curabitur porta dui
              eget blandit amet tempor. Lorem ipsum dolor sit amet consectetur.
              Vel curabitur porta dui eget blandit amet tempor. Amet ultrices ac
              ipsum eleifend feugiat sit. Et aliquam odio vitae. Lorem ipsum
              dolor sit amet consectetur. Vel curabitur porta dui eget blandit
              amet tempor.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavCard;
