import React, { useEffect } from "react";
import AboutBanner from "../../Components/AboutUs/AboutBanner";
import Hesitation from "../../Components/AboutUs/Hesitation";
import VerityCards from "../../Components/AboutUs/VerityCards";
import Process from "../../Components/AboutUs/Process";
import HowWork from "../../Components/AboutUs/HowWork";
import FavCard from "../../Components/AboutUs/FavCard";

const AboutUs = (props) => {
  return (
    <>
      <div className="AboutUs">
        <AboutBanner />
        <Hesitation />
        <VerityCards />
        <Process />
        <HowWork />
        <FavCard />
      </div>
    </>
  );
};

export default AboutUs;
