import { endpoint } from "../config/endpoint";
import { sendRequest } from "./http-services";

export const getCardList = (payload) => {
  console.log("🚀 ~ getCardList ~ payload:", payload);
  return sendRequest(endpoint.card.SEARCH, "GET", payload);
};

export const getCard = (payload) => {
  return sendRequest(endpoint.card.SEARCH, "GET", payload);
};

export const popularCard = (payload) => {
  return sendRequest(endpoint.card.POPULAR, "GET", payload);
};

export const cardDetails = (slug) => {
  return sendRequest(`${endpoint.card.DETAILS}/${slug}`, "GET");
};

export const cardSearch = (payload) => {
  console.log("payload", payload);
  return sendRequest(endpoint.card.SEARCH, "GET", payload);
};

export const cardInfo = (cardId) => {
  return sendRequest(`${endpoint.card.INFO}/${cardId}`, "GET");
};

export const cardComparison = (cardId) => {
  return sendRequest(
    `${endpoint.card.CARD_COMPARISON}?cardId=${cardId}`,
    "GET"
  );
};

export const PriceCardData = (payload) => {
  return sendRequest(endpoint.card.CARD_PRICE, "POST", payload);
};

export const addSubscriber = (payload) => {
  return sendRequest(endpoint.card.SUBSCRIBER, "POST", payload);
};

export const addRequest = (payload) => {
  return sendRequest(endpoint.card.REQUEST, "POST", payload);
};

export const searchFilter = (payload) => {
  return sendRequest(endpoint.card.FILTER, "GET", payload);
};
