import React, { useEffect, useState } from "react";

const AnimatedNumber = () => {
  const [fees, setFees] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [transparency, setTransparency] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFees((prevFees) => (prevFees < 0 ? prevFees + 2 : 0));
      setMarkup((prevMarkup) => (prevMarkup < 0 ? prevMarkup + 4 : 0));
      setTransparency((prevTransparency) =>
        prevTransparency < 100 ? prevTransparency + 5 : 100
      );
    }, 100);

    if (fees === 0 && markup === 0 && transparency === 100) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [fees, markup, transparency]);

  return (
    <div className="AnimatedNumberbar" data-aos="fade-up">
      <ul className="counters">
        <li>
          <h6>{fees}%</h6>
          <p>Fees</p>
        </li>
        <li className="borderbar">
          <h6>{markup}%</h6>
          <p>Markup</p>
        </li>
        <li>
          <h6>{transparency}%</h6>
          <p>Transparency</p>
        </li>
      </ul>
    </div>
  );
};

export default AnimatedNumber;
