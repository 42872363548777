import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import LineChart from "./LineChart";
import WebsiteComparison from "./WebsiteComparison";
import SingleCard from "../../Components/SingleCard";
import { cardDetails } from "../../services/card-services";

const ProductDetail = (props) => {
  const [card, setCard] = useState(null);
  const { slug } = useParams();
  const websiteComparisonRef = useRef(null);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await cardDetails(slug);
        setCard(response.data);
      } catch (error) {
        console.error("Error fetching card details:", error);
      }
    };

    if (slug) {
      fetchCardDetails();
    }
  }, [slug]);

  const handleCardClick = () => {
    if (websiteComparisonRef.current) {
      websiteComparisonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="ProductDetail">
        <div className="ProductTitle">
          {/* <div className="container">
            <Breadcrumb className="breadcrumbs-bar">
              <Link className="breadcrumb-item" to="/">
                Home
              </Link>
              <Link className="breadcrumb-item " to="/">
                Sets
              </Link>
              <Link to="/allproducts" className="breadcrumb-item ">
                Products
              </Link>
              <Breadcrumb.Item active>{card?.cardName}</Breadcrumb.Item>
            </Breadcrumb>
          </div> */}
        </div>
        <div className="productdetailbar">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="product-left">
                  <div className="product-left-sticky product-details-card-main">
                    <div className="product-appear">
                      <div className="slick-slide allproduct-list d-flex float-none">
                        {card && (
                          <SingleCard
                            showBackBtn={true}
                            cardName={card.cardName}
                            cardUrl={card.cardUrl}
                            releaseDate={card.releaseDate}
                            // isSealed={card.isSealed}
                            type={card?.type}
                            price={
                              card?.price ? `£${card?.price.toFixed(2)}` : ""
                            }
                            Cheapest={card?.price ? "Starting from" : ""}
                            onClick={handleCardClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {card && (
                <div className="col-md-8 col-lg-9">
                  <div className="product-ryt">
                    <div
                      className="allwebsite  shadowbox"
                      ref={websiteComparisonRef}
                    >
                      <WebsiteComparison cardId={card._id} />
                    </div>
                    <div className="graphbar shadowbox">
                      <LineChart cardId={card._id} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
