import "../src/Css/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./hooks/useThemeContext";
import AppRouter from "./AppRouter";

function App() {
  return (
    <>
      <ThemeProvider>
        <Router>
          <AppRouter />
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
