import React, { useState } from "react";
import ProductTitle from "./ProductTitle";
import ProductSearchFilter from "../../Components/Products/ProductSearchFilter";
import { Link } from "react-router-dom";

const Sellertools = (props) => {
  const pageTitle = "Seller Tools";

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div className="AllProduct">
        <ProductTitle title={pageTitle} />
        <div className="products-filter-block">
          <div className={`filterrow ${isFilterOpen ? "open" : ""}`}>
            <div className="filter-searchbar opensearchfilter">
              <ProductSearchFilter />
            </div>
            <div className="product-appear">
              <div className="btn-active-filter">
                <button className="btn btnblack" onClick={toggleFilter}>
                  Filter By: <i class="bi bi-funnel-fill"></i>
                </button>
              </div>
              <div className="slick-slide allproduct-list">
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/productdetail">
                  <div className="item-box">
                    <div className="itemimg">
                      <img src="/images/p.png" alt="item imgs" />
                    </div>
                    <div className="item-detail">
                      <h4>Pokemon Explosive Flame s2a Japanese Booster Box</h4>
                      <h5>Sealed</h5>
                      <h6>Release date: 21 Oct 2017</h6>
                      <div className="detailprice">
                        <p className="price">$41.88</p>
                        <p className="arrow">
                          <img src="/images/arrow.svg" alt="icon" />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sellertools;
