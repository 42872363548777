import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { searchFilter } from "../../services/card-services";

const ProductSearchFilter = ({ filter, setFilter, closefun }) => {
  const [language, setLanguage] = useState([]);
  const [type, setType] = useState([]);
  const [era, setEra] = useState([]);
  const [sets, setSets] = useState([]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFilter((prev) => {
      const newFilter = { ...prev };
      if (checked) {
        if (!newFilter[name].includes(value)) {
          newFilter[name] = [...newFilter[name], value];
        }
      } else {
        newFilter[name] = newFilter[name].filter((item) => item !== value);
      }
      return newFilter;
    });
  };

  // const handleSortChange = (e) => {
  //   setFilter((prev) => {
  //     const newFilter = { ...prev };
  //     newFilter["sort"] = e.target?.value;
  //     return newFilter;
  //   });
  // }

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchFilter();
      setLanguage(response.data.languages);
      setEra(response.data.eras);
      setType(response.data.types);
      setSets(response.data.sets);
    };
    fetchData();
  }, []);

  return (
    <div className="ProductSearchFilter">
      <div className="filters-row">
        <h2 className="close-filter-btn" onClick={closefun}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M21 21L1 1M21 1L1 21"
              stroke="#000"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h2>
        <h4>Filter By:</h4>
        <Accordion defaultActiveKey={["0", "1", "2", "4", "6"]}>
          {/* <Accordion.Item eventKey="6">
            <Accordion.Header>Sort</Accordion.Header>
            <Accordion.Body>
              <Form.Select value={filter?.sort} onChange={handleSortChange}>
                <option value="latest">Latest Added</option>
                <option value="oldest">Oldest Added</option>
                <option value="release_date_desc">
                  Release Date (High to Low)
                </option>
                <option value="release_date_asc">
                  Release Date (Low to High)
                </option>
                <option value="price_desc">Price (High to Low)</option>
                <option value="price_asc">Price (Low to High)</option>
              </Form.Select>
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="2">
            <Accordion.Header>Type</Accordion.Header>
            <Accordion.Body>
              <ul className="checkbox-containbar">
                {type.map((type, index) => (
                  <li key={index}>
                    <label className="checkbox-contain">
                      {type}
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="type"
                        value={type}
                        checked={filter?.type?.includes(type)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Language</Accordion.Header>
            <Accordion.Body>
              <ul className="checkbox-containbar">
                {language.map((language, index) => (
                  <li key={index}>
                    <label className="checkbox-contain">
                      {language}
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="language"
                        value={language}
                        checked={filter?.language?.includes(language)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>ERA</Accordion.Header>
            <Accordion.Body>
              <ul className="checkbox-containbar">
                {era.map((era, index) => (
                  <li key={index}>
                    <label className="checkbox-contain">
                      {era}
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="era"
                        value={era}
                        checked={filter?.era?.includes(era)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Set</Accordion.Header>
            <Accordion.Body>
              <ul className="checkbox-containbar">
                {sets.map((set, index) => (
                  <li key={index}>
                    <label className="checkbox-contain">
                      {set}
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="set"
                        value={set}
                        checked={filter?.set?.includes(set)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="3">
            <Accordion.Header>Set</Accordion.Header>
            <Accordion.Body>
              <ul className="checkbox-containbar">
                <li>
                  <label className="checkbox-contain">
                    Yes
                    <input
                      type="radio"
                      onChange={handleChange}
                      name="isSet"
                      value="true"
                      checked={
                        filter?.isSet === true || filter?.isSet === "true"
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="checkbox-contain">
                    No
                    <input
                      type="radio"
                      onChange={handleChange}
                      name="isSet"
                      value="false"
                      checked={
                        filter?.isSet === false || filter?.isSet === "false"
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
    </div>
  );
};

export default ProductSearchFilter;
