import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import SingleCard from "../SingleCard";
import { popularCard } from "../../services/card-services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import WebsiteComparison from "../../Pages/Product/WebsiteComparison";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <i
        className="bi bi-arrow-left"
        style={{ fontSize: "24px", color: "#000" }}
      ></i>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <i
        className="bi bi-arrow-right"
        style={{ fontSize: "24px", color: "#000" }}
      ></i>
    </div>
  );
};

const MostPopular = () => {
  const [popularCards, setPopularCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await popularCard();
        setPopularCards(response?.data?.docs);
      } catch (error) {
        console.error("Error fetching popular cards:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCards();
  }, []);

  const handleCardClick = (cardId) => {
    let viewedCards =
      JSON.parse(localStorage.getItem("recentlyViewedCards")) || [];
    if (!viewedCards.includes(cardId)) {
      viewedCards.push(cardId);
      localStorage.setItem("recentlyViewedCards", JSON.stringify(viewedCards));
    }
  };

  const settings = {
    dots: true,
    className: "center",
    slidesToShow: 5,
    autoplay: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    focusOnSelect: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 574,
        settings: {
          slidesToShow: 2,
          rows: 2,
          centerPadding: "60px",
        },
      },
    ],
  };

  return (
    <div className="MostPopular" data-aos="fade-up">
      <div className="bgoverlay"></div>
      <h2 className="popular-card">Most Popular</h2>
      <div className="product_slider">
        <Slider {...settings}>
          {loading
            ? Array(5)
                .fill(0)
                .map((_, index) => (
                  <div key={index}>
                    <Skeleton height={200} width={150} />
                  </div>
                ))
            : popularCards.map((card) => (
                <Link
                  to={`/productdetail/${card?.slug}`}
                  key={card._id}
                  onClick={() => handleCardClick(card._id)}
                >
                  <SingleCard
                    cardName={card?.cardName}
                    cardUrl={card?.cardUrl}
                    releaseDate={card?.releaseDate}
                    type={card?.type}
                    price={card?.price ? `£${card?.price.toFixed(2)}` : ""}
                    Cheapest={card?.price ? "Starting from" : ""}
                  />
                </Link>
              ))}
        </Slider>
      </div>
    </div>
  );
};

export default MostPopular;
