import React, { useState } from "react";
import Request from "../../Components/Modals/Request";
const Nosearch = (props) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div className="Search">
        <div className="container">
          <div className="resultbar no-result">
            <h2 className="h2 " data-aos="fade-up">
              {`You searched for “${props.searchTerm}”`}
            </h2>
            <h5 className="h5" data-aos="fade-up">
              No results found
            </h5>
            <div className="whennosearch">
              <button
                onClick={() => setModalShow(true)}
                className="btn btnblack"
                type="button"
              >
                Request Product
              </button>
              <Request show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nosearch;
