import React, { useEffect } from "react";
import Banner from "../../Components/Home/Banner";
import MostPopuler from "../../Components/Home/MostPopuler";
import RecentlyViewed from "../../Components/Home/RecentlyViewed";

const Home = (props) => {
  return (
    <>
      <div className="homebar">
        <Banner />
        <MostPopuler />
        <RecentlyViewed />
      </div>
    </>
  );
};

export default Home;
