export const constants = {
  // DOMAIN_URL: "https://cab-booking.dev.seraphic.io/",
  // DOMAIN_URL: "http://localhost:9005",
  DOMAIN_URL: "https://api.tcgsearch.com",
};

export const LANGUAGE = {
  ENGLISH: "english",
  JAPANESE: "japanese",
  KOREAN: "korean",
};

export const ERA = {
  SCARLET: "scarlet & violet",
  SWORD: "sword & shield",
  SUN: "sun & moon",
};

export const TYPE = {
  BOOSTER: "booster box",
  ELITE: "elite trainer box",
};
