import React, { useEffect } from "react";
import SubscribeModal from "../../Modals/SubscribeModal";
import { Link, useLocation } from "react-router-dom";

const Footer = (props) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="Footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-col">
                <div className="footer-logo">
                  <a href="/">
                    <img src="/images/logo.svg" alt="logo" />
                  </a>
                </div>
                {/* <h4>TCG search</h4> */}
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur. Vel curabitur porta
                  dui eget blandit amet tempor.
                </p> */}

              </div>
            </div>
            <div className="col-md-4">
              <ul className="footer-menu footer-menu-list">
                <li>
                  <Link
                    className={`nav-link ${isActive("/allproducts") ? "active" : ""
                      }`}
                    to="/allproducts"
                  >
                    All Products
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="footer-menu">
                <li className="foot-last">
                  <div class="socialmediabar">

                    <button
                      type="button"
                      href="#"
                      onClick={() => setModalShow(true)}
                      className="btn btnblack"
                    >
                      Subscribe Now
                    </button>
                    <a href="https://www.instagram.com/tcgsearch/" target="_blank" className="btninsta">
                      <i class="bi bi-instagram"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyryt-text">
            <p>Copyright ® 2024 Company All rights Reserved</p>
          </div>
        </div>
      </div>
      <SubscribeModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Footer;
